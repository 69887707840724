$(document).ready(function () {
    const checks = document.querySelectorAll('.homepage-layout-image-block-item-icon-checkmark-check');

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const target = entry.target;

            if (entry.isIntersecting) {
                target.classList.add('check-animation');
                return;
            }
            target.classList.remove('check-animation');
        });
    });

    for (let i = 0; i < checks.length; i++) {
        observer.observe(checks[i]);
    }
});
